import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { useListBox, useOption } from 'react-aria';

import { Link } from '@/design-system/atoms/Link';

import styles from './List.module.scss';

const ListItem = (props) => {
    const { item, state, isFixedItem, setIsFocused } = props;

    const ref = React.useRef(null);
    const { optionProps, isSelected, isFocused } = useOption(
        {
            key: item.key,
        },
        state,
        ref,
    );

    const { href, target, csr, ...rest } = optionProps;
    const Component = isFixedItem ? Link : React.Fragment;
    const componentProps = isFixedItem
        ? {
              tabIndex: -1,
              href,
              target,
              csr,
              onClick: href ? (e) => e.stopPropagation() : undefined,
          }
        : {};

    React.useEffect(() => {
        if (setIsFocused) setIsFocused(isFocused);
    }, [isFocused, setIsFocused]);

    return (
        <li
            {...rest}
            ref={ref}
            className={classNames(
                styles['list-item'],
                isSelected && styles['list-item--selected'],
                isFocused && styles['list-item--focused'],
                isFixedItem && styles['list-item--fixed-item'],
            )}
        >
            <Component {...componentProps}>{item.rendered}</Component>
        </li>
    );
};

/**
 * This component is responsible to render the list of suggested items.
 *
 * It isn't recommended to use this component out of the ComboBox component,
 * because it depends of values returned from the `useComboBoxState` and `useComboBox` hooks.
 */
export const List = (props) => {
    let { listBoxRef, state, hasFixedItem, setIsFocused } = props;

    let { listBoxProps } = useListBox(props, state, listBoxRef);

    const collection = [...state.collection];
    const items = hasFixedItem ? collection.slice(0, -1) : collection;
    const lastItem = hasFixedItem ? collection.slice(-1)[0] : null;

    return (
        <div
            {...listBoxProps}
            ref={listBoxRef}
            className={styles['list-wrapper']}
            aria-live="polite"
        >
            <ul role="none" className={styles['list']}>
                {items.map((item) => (
                    <ListItem
                        key={item.key}
                        item={item}
                        state={state}
                        setIsFocused={setIsFocused}
                    />
                ))}
            </ul>
            {hasFixedItem && (
                <ul className={classNames(styles['list'], styles['list--fixed-item'])}>
                    <ListItem
                        key={lastItem.key}
                        item={lastItem}
                        state={state}
                        setIsFocused={setIsFocused}
                        isFixedItem
                    />
                </ul>
            )}
        </div>
    );
};

List.propTypes = {
    /**
     * listBoxRef from `useComboBox`
     */
    listBoxRef: PropTypes.object,
    /**
     * state from `useComboBoxState`
     */
    state: PropTypes.object,
    /**
     * If the list has a fixed item
     */
    hasFixedItem: PropTypes.bool,
    /**
     * Function to set the hovering state of the item
     */
    setIsFocused: PropTypes.func,
};
