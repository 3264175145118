import React from 'react';
import { Link as InternalLink } from 'react-router-dom';

export const Link = React.forwardRef((props, ref) => {
    const LinkTag = props.csr ? InternalLink : 'a';

    // eslint-disable-next-line jsx-a11y/anchor-has-content
    return <LinkTag ref={ref} {...props} to={props.csr ? props.href : undefined} />;
});

Link.displayName = 'Link';
