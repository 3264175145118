import { buildSearchBox } from '@coveo/headless';
import * as changeCase from 'change-case';
import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { getHeadlessEngine } from '@/libs/coveo/getHeadlessEngine';
import { trackInteraction } from '@/utils/analytics';

const PARAM_KEY = 'q';

// Default Header context state
const defaultValue = {
    coveo: {
        controller: {
            searchBox: null,
        },
    },
    searchValue: '',
    suggestions: [],
    handleSetSearchValue: () => {},
    handleSelectSuggestion: () => {},
    submitForm: () => {},
};
const HeaderContext = React.createContext(defaultValue);

export const HeaderProvider = (props) => {
    const { children, coveo, searchResultsPage } = props;
    const [suggestions, setSuggestions] = useState([]);
    const [searchValue, setSearchValue] = useState('');

    const controllerSearchBox = useMemo(() => {
        const engine = getHeadlessEngine('header', coveo);
        const controllerSearchBox = buildSearchBox(engine, {
            options: { numberOfSuggestions: 10 },
        });
        return controllerSearchBox;
    }, [coveo]);

    const handleSetSearchValue = useCallback(
        (value) => {
            if (value?.length >= 2) {
                controllerSearchBox.updateText(value);
            } else {
                controllerSearchBox.clear();
            }
            setSearchValue(value);
        },
        [controllerSearchBox],
    );

    const handleSubmit = useCallback(
        (value) => {
            if (!value) {
                return;
            }
            // Analytics
            trackInteraction({
                eventName: 'search',
                componentName: 'Navigation',
                componentTitle: 'navigation',
                interactionType: 'search_results',
                selector: 'search',
                searchTerm: value,
            });

            const newSearchParams = new URLSearchParams();
            newSearchParams.set(PARAM_KEY, value);
            const newUrl = `${searchResultsPage?.href}?${newSearchParams.toString()}`;

            window.location.href = newUrl;
        },
        [searchResultsPage],
    );

    const handleSelectSuggestion = useCallback(
        (item) => {
            if (!item) {
                controllerSearchBox.clear();
                return;
            }
            // Analytics
            trackInteraction({
                eventName: 'search',
                componentName: 'Navigation',
                componentTitle: 'navigation',
                interactionType: 'predictive_click_text',
                actionLabel: item.text,
                selector: 'search',
                searchTerm: item.text,
            });
            setSearchValue(item.text);
            handleSubmit(item.text);
        },
        [controllerSearchBox, handleSubmit],
    );

    const submitForm = useCallback(
        (event) => {
            event.preventDefault();
            handleSubmit(searchValue);
        },
        [handleSubmit, searchValue],
    );

    useEffect(() => {
        const unsubscribe = controllerSearchBox.subscribe(() => {
            const newSuggestions = controllerSearchBox.state.suggestions.map((s, i) => {
                return {
                    id: i,
                    text: changeCase.capitalCase(s.rawValue),
                };
            });

            setSuggestions(newSuggestions);
        });
        return () => {
            // Unsubscribe from the searchbox
            unsubscribe();
        };
    }, [controllerSearchBox]);

    // Header context state
    const value = useMemo(
        () => ({
            searchValue,
            suggestions,
            handleSetSearchValue,
            handleSelectSuggestion,
            submitForm,
        }),
        [searchValue, suggestions, handleSetSearchValue, handleSelectSuggestion, submitForm],
    );

    return <HeaderContext.Provider value={value}>{children}</HeaderContext.Provider>;
};

export const useHeaderContext = () => {
    const context = React.useContext(HeaderContext);

    if (!context) {
        throw new Error(`'useHeaderContext' must be used within a HeaderProvider`);
    }

    return context;
};
