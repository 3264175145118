import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import sanitizeHtml from 'sanitize-html';

import { trackInteraction } from '@/utils/analytics';
import { composeRefs } from '@/utils/composeRefs';
import sanitizeWhiteList from '@/utils/sanitize-html.whitelist';

import styles from './RichTextEditor.module.scss';

/**
 * @see https://github.com/adobe/aem-project-archetype/blob/develop/src/main/archetype/ui.frontend.react/src/components/Text/Text.js
 */
export const RichTextEditor = React.forwardRef((props, ref) => {
    const { className, text, analytics, onAnchorClick } = props;

    const internalRef = React.useRef();

    React.useEffect(() => {
        if (typeof window === 'undefined') {
            return;
        }
        const anchorsElement = internalRef.current.querySelectorAll('a');

        const handleAnchorClick = (e) => {
            // e.preventDefault();
            // const href = e.target.getAttribute('href');
            if (typeof onAnchorClick === 'function') {
                onAnchorClick(e);
            }
            trackInteraction({
                interactionType: 'inline_link',
                actionLabel: e.target.textContent,
                linkHref: e.target.getAttribute('href'),
                selector: 'a',
                ...analytics,
            });
            console.log(
                'TODO - when we have the navigate strategy we can handle it in a appropriated way',
            );
        };

        anchorsElement.forEach((a) => {
            a.setAttribute('data-trigger', analytics?.selector || 'a');
            a.addEventListener('click', handleAnchorClick);
        });

        return () => {
            anchorsElement.forEach((a) => {
                a.removeEventListener('click', handleAnchorClick);
            });
        };
    }, [analytics, onAnchorClick]);

    return (
        <div
            ref={composeRefs(ref, internalRef)}
            className={classnames(styles['rich-text-editor'], className)}
            data-rte-editelement
            dangerouslySetInnerHTML={{
                __html: sanitizeHtml(text, sanitizeWhiteList),
            }}
        />
    );
});

RichTextEditor.displayName = 'RichTextEditor';

RichTextEditor.propTypes = {
    /**
     * HTML content
     */
    text: PropTypes.string,
};
