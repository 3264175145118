/**
 * Used to add more them one React Reference into the same React Component
 *
 * @param  {...(Function|{current: any})} refs List of React Reference
 * @returns void
 */
export const composeRefs = (...refs) => {
    return (node) =>
        refs.forEach((ref) => {
            if (typeof ref === 'function') {
                ref(node);
            } else if (ref != null) {
                ref.current = node;
            }
        });
};
