/**
 * analytics type
 */

import PropTypes from 'prop-types';
const analyticsPropType = PropTypes.shape({
    eventName: PropTypes.string,
    componentName: PropTypes.string,
    componentTitle: PropTypes.string,
    actionLabel: PropTypes.string,
    selector: PropTypes.string,
    interactionType: PropTypes.string,
    linkHref: PropTypes.string,
    searchTerm: PropTypes.string,
    searchPosition: PropTypes.number,
    providerName: PropTypes.string,
    providerSpecialties: PropTypes.string,
    locationName: PropTypes.string,
    locationAddress: PropTypes.string,
    locationParent: PropTypes.string,
    locationPhone: PropTypes.string,
    locationOpen: PropTypes.string,
    locationWait: PropTypes.string,
    zipCode: PropTypes.string,
    allFilters: PropTypes.object,
});

export default analyticsPropType;
