import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import footerLogo from '@/assets/images/footer-logo.png';
import { linkPropType } from '@/custom-prop-types';
import { Button } from '@/design-system/atoms/Button';
import { ContentContainer } from '@/design-system/atoms/ContentContainer';
import { Grid, GridColumn } from '@/design-system/atoms/Grid';
import { Image } from '@/design-system/atoms/Image';
import { Text } from '@/design-system/atoms/Text';
import { Theme } from '@/design-system/atoms/Theme';
import Typography from '@/design-system/atoms/Typography';
import { trackInteraction } from '@/utils/analytics';

import styles from './Footer.module.scss';
/*
 * The Footer Component is a section at the bottom of the page that typically
 * contains information about the site, links to other pages, and contact information.
 * It is used to provide users with additional information and resources.
 */
const Footer = ({
    testId,
    urlOverride,
    columnLinks,
    socialLinks,
    copyrightLabel,
    bottomLinks,
    socialColumnLabel,
    disableCSR,
    disabled,
    ...props
}) => {
    const handleLogoClick = React.useCallback(
        (event) => {
            trackInteraction({
                componentName: 'Footer',
                componentTitle: 'footer',
                interactionType: 'footer_logo',
                actionLabel: 'Atlantic Health Systems Homepage',
                linkHref: urlOverride ? urlOverride : '/',
                selector: 'logo',
            });
        },
        [urlOverride],
    );

    return (
        <footer data-testid={testId} className={styles['footer']} {...props}>
            <Theme name={Theme.NAME.DARK}>
                <ContentContainer>
                    <div className={styles['footer__spacing-wrapper']}>
                        <Grid className={styles['footer-main']} row={{ sm: 1, md: 3, lg: 2 }}>
                            <GridColumn
                                colSpan={{ sm: 4, md: 6, lg: 12 }}
                                className={styles['footer-logo']}
                            >
                                <a
                                    href={urlOverride ? urlOverride : '/'}
                                    aria-label="Atlantic Health Systems Homepage"
                                    onClick={handleLogoClick}
                                    data-trigger={'logo'}
                                >
                                    <Image src={footerLogo} />
                                </a>
                            </GridColumn>
                            {columnLinks?.map((column, columnIdx) => {
                                const colStartLg = columnIdx === 0 ? 4 : columnIdx === 1 ? 7 : 10;
                                const colStartMd = columnIdx === 0 ? 1 : columnIdx === 1 ? 4 : 1;
                                return (
                                    <GridColumn
                                        key={`column-${columnIdx}`}
                                        colSpan={{ sm: 4, md: 3, lg: 3 }}
                                        className={styles['footer-column']}
                                        colStart={{ sm: 1, md: colStartMd, lg: colStartLg }}
                                    >
                                        <div className={styles['footer-nav']}>
                                            <Text
                                                tag="div"
                                                variant={Text.VARIANT.E1}
                                                content={column.title}
                                                className={styles['footer-nav__title']}
                                            />

                                            <ul
                                                className={styles['footer-nav__list']}
                                                role="menubar"
                                            >
                                                {column?.links.map((link, idx) => {
                                                    return (
                                                        <li
                                                            key={idx}
                                                            className={styles['footer-nav__item']}
                                                            role="none"
                                                        >
                                                            <Button
                                                                href={link.href}
                                                                target={link.target}
                                                                className={classNames(
                                                                    styles[`footer-nav__link`],
                                                                )}
                                                                buttonStyle={Button.STYLE.TERTIARY}
                                                                label={link.label}
                                                                aria-label={link.ariaLabel}
                                                                role="menuitem"
                                                                analytics={{
                                                                    componentName: 'Footer',
                                                                    interactionType: 'footer',
                                                                    selector: 'footer',
                                                                }}
                                                                csr={
                                                                    disableCSR
                                                                        ? !disableCSR
                                                                        : link?.csr
                                                                }
                                                            />
                                                        </li>
                                                    );
                                                })}
                                            </ul>
                                        </div>
                                        {columnIdx === columnLinks.length - 1 && (
                                            <div
                                                key={`column-social-links`}
                                                className={styles['footer-column']}
                                            >
                                                <div className={styles['footer-social']}>
                                                    <>
                                                        <div>
                                                            <Text
                                                                tag="div"
                                                                variant={Text.VARIANT.E1}
                                                                content={socialColumnLabel}
                                                                className={
                                                                    styles['footer-social__title']
                                                                }
                                                            />

                                                            <ul
                                                                className={
                                                                    styles['footer-social__list']
                                                                }
                                                                role="menubar"
                                                            >
                                                                {socialLinks?.map((sLinks, idx) => {
                                                                    return (
                                                                        <li
                                                                            key={idx}
                                                                            className={
                                                                                styles[
                                                                                    'footer-social__item'
                                                                                ]
                                                                            }
                                                                            role="none"
                                                                        >
                                                                            <Button
                                                                                href={sLinks?.href}
                                                                                target={
                                                                                    sLinks?.target
                                                                                }
                                                                                className={classNames(
                                                                                    styles[
                                                                                        'footer-social__link'
                                                                                    ],
                                                                                )}
                                                                                buttonStyle={
                                                                                    Button.STYLE
                                                                                        .TERTIARY
                                                                                }
                                                                                label={
                                                                                    sLinks?.label
                                                                                }
                                                                                aria-label={
                                                                                    sLinks?.ariaLabel
                                                                                }
                                                                                role="menuitem"
                                                                                iconSize="small"
                                                                                iconName={
                                                                                    sLinks?.type
                                                                                }
                                                                                analytics={{
                                                                                    componentName:
                                                                                        'Footer',
                                                                                    componentTitle:
                                                                                        'footer',
                                                                                    interactionType:
                                                                                        'social_cta',
                                                                                    actionLabel:
                                                                                        sLinks.label ||
                                                                                        sLinks.ariaLabel,
                                                                                    selector:
                                                                                        'social',
                                                                                }}
                                                                                csr={
                                                                                    disableCSR
                                                                                        ? !disableCSR
                                                                                        : sLinks?.csr
                                                                                }
                                                                            />
                                                                        </li>
                                                                    );
                                                                })}
                                                            </ul>
                                                        </div>
                                                    </>
                                                </div>
                                            </div>
                                        )}
                                    </GridColumn>
                                );
                            })}
                        </Grid>
                        <div className={styles['footer-bottom']}>
                            <ul className={styles['footer-utility__list']} role="menubar">
                                {bottomLinks && (
                                    <>
                                        {bottomLinks?.map((link, idx) => {
                                            return (
                                                <li
                                                    key={idx}
                                                    className={styles['footer-utility__item']}
                                                    role="none"
                                                >
                                                    <Button
                                                        href={link.href}
                                                        target={link.target}
                                                        className={classNames(
                                                            styles[`footer-utility__link`],
                                                        )}
                                                        buttonStyle={Button.STYLE.TEXT_EXTRA_SMALL}
                                                        label={link.label}
                                                        aria-label={link.ariaLabel}
                                                        role="menuitem"
                                                        analytics={{
                                                            componentName: 'Footer',
                                                            interactionType: 'footer',
                                                            selector: 'footer',
                                                        }}
                                                        csr={disableCSR ? !disableCSR : link?.csr}
                                                    />
                                                </li>
                                            );
                                        })}
                                    </>
                                )}
                            </ul>
                            <Typography
                                tag="div"
                                variant={Typography.VARIANT.CTA4}
                                content={copyrightLabel}
                                className={styles['footer-copyright']}
                            />
                        </div>
                    </div>
                </ContentContainer>
            </Theme>
        </footer>
    );
};

const columnPropType = PropTypes.arrayOf(
    PropTypes.shape({
        title: PropTypes.string,
        links: PropTypes.arrayOf(linkPropType),
    }),
);

Footer.propTypes = {
    content: PropTypes.string,
    /** Test ID */
    testId: PropTypes.string,
    /** Logo */
    logo: PropTypes.shape({
        href: PropTypes.string,
        image: PropTypes.shape(Image.propTypes),
    }),
    columnOne: columnPropType,
    columnTwo: columnPropType,
    columnThree: columnPropType,
    columnFour: columnPropType,
    socialLinks: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.string,
            ariaLabel: PropTypes.string,
            href: PropTypes.string,
            target: PropTypes.string,
            icon: PropTypes.string,
        }),
    ),
    copyright: PropTypes.string,
    utilityLinks: PropTypes.arrayOf(linkPropType),
    /**
     * Disable client side routing
     */
    disableCSR: PropTypes.bool,
};

export default Footer;
