/**
 * Takes an array of tuples and returns an object with the first element of the tuple as the key and the second element as the value.
 */
export function objectFromArray(array) {
    if (Array.isArray(array)) {
        return array.reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {});
    }

    return array;
}
