import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';

import * as customPropTypes from '@/custom-prop-types';
import { trackInteraction } from '@/utils/analytics';

import { Icon } from '../Icons/Icon';
import Typography from '../Typography/Typography';
import styles from './Button.module.scss';

/**
 * The Button component serves as a flexible button or link element.
 *
 * Modes:
 * - Button: ```<button type='button'></button>``` with click handler
 * - Anchor: ```<a href=''></a>``` link with href and target attributes
 *
 * Themes:
 * - Primary: default button style
 * - Secondary: secondary button style
 * - Tertiary: tertiary button style
 * - Text: text button style
 * Primary, Secondary, and Tertiary styles can be used with or without an icon or as an icon only button.
 */
const Button = React.forwardRef(
    (
        {
            component: Component,
            id,
            className,
            buttonStyle, // Style of the button element
            label, // visible button label
            ariaLabel, // Screen reader label for button
            href, //link destination
            target,
            csr, //client side routing
            type,
            disabled,
            fullWidth,
            alignment, //allow for left aligned text on buttons

            // Icon
            iconComponent,
            iconName,
            iconRight,
            iconSize,

            //analytics
            analytics,
            onClick,

            // Other Button Only Props
            ...props
        },
        ref,
    ) => {
        const ButtonTag = Component ?? (href ? (csr ? Link : 'a') : 'button');
        const defaultSelector = Component ?? (href ? 'a' : 'button');
        const iconOnly = iconComponent != null && !label;

        const [position, ...iconElements] = React.useMemo(() => {
            const iconPropsSize = {
                ...(iconSize === Button.ICON_SIZE.LARGE
                    ? { height: 24, width: 24 }
                    : { height: 20, width: 20 }),
            };

            if (Array.isArray(iconComponent)) {
                if (iconComponent.length !== 2) {
                    const right = iconComponent[0];

                    return [
                        'right',
                        <Icon
                            key="icon-right"
                            name={right.iconName}
                            {...right.iconProps}
                            {...iconPropsSize}
                        />,
                    ];
                } else {
                    const left = iconComponent[0];
                    const right = iconComponent[1];

                    return [
                        'left-and-right',
                        <Icon
                            key="icon-left"
                            name={left.iconName}
                            {...left.iconProps}
                            {...iconPropsSize}
                        />,
                        <Icon
                            key="icon-right"
                            name={right.iconName}
                            {...right.iconProps}
                            {...iconPropsSize}
                        />,
                    ];
                }
            }

            const leftOrRight =
                buttonStyle === Button.STYLE.TEXT_TITLE && iconRight == null
                    ? 'right'
                    : iconRight
                      ? 'right'
                      : 'left';

            if (iconName) {
                return [leftOrRight, <Icon key="icon" name={iconName} {...iconPropsSize} />];
            }

            if (!iconComponent) {
                return [];
            }

            const IconComponent = iconComponent;

            return [
                leftOrRight,
                <IconComponent key="icon" className={styles.icon} {...iconPropsSize} />,
            ];
        }, [iconName, iconComponent, buttonStyle, iconRight, iconSize]);

        const handleDisabledEvent = React.useCallback((e) => {
            e.preventDefault();
        }, []);

        // click handler
        const handleClickEvent = React.useCallback(
            (e) => {
                if (analytics) {
                    trackInteraction({
                        selector: defaultSelector,
                        actionLabel: label || ariaLabel,
                        linkHref: href,
                        interactionType: buttonStyle === 'primary' ? 'primary_cta' : 'cta',
                        ...analytics,
                    });
                }

                if (typeof onClick === 'function') {
                    onClick(e);
                }
            },
            [onClick, analytics, defaultSelector, label, ariaLabel, href, buttonStyle],
        );
        return (
            <ButtonTag
                ref={ref}
                className={classnames(
                    styles.button,
                    styles[`button--${buttonStyle}`],
                    iconOnly &&
                        iconSize !== Button.ICON_SIZE.LARGE &&
                        styles[`button--iconOnly--small`],
                    iconOnly &&
                        iconSize === Button.ICON_SIZE.LARGE &&
                        styles[`button--iconOnly--large`],
                    disabled && styles['disabled'],
                    className,
                    fullWidth && fullWidth.sm && styles['button--full-width-sm'],
                    fullWidth && fullWidth.md && styles['button--full-width-md'],
                    fullWidth && fullWidth.lg && styles['button--full-width-lg'],
                    alignment === 'left' && styles['button--left-aligned'],
                )}
                id={id}
                href={href}
                target={href ? target : undefined}
                to={csr ? href : undefined}
                type={href ? undefined : type}
                aria-disabled={disabled ? 'true' : undefined}
                aria-label={ariaLabel}
                {...props}
                data-trigger={analytics ? analytics?.selector || defaultSelector : undefined}
                onClick={disabled ? handleDisabledEvent : handleClickEvent}
                onKeyPress={disabled ? handleDisabledEvent : props.onKeyPress}
            >
                {iconOnly ? (
                    <>{iconElements}</>
                ) : buttonStyle === Button.STYLE.TEXT_TITLE ? (
                    <>
                        {label && (
                            <Typography
                                variant={
                                    buttonStyleToTypographyVariant[buttonStyle] ??
                                    Typography.VARIANT.CTA1
                                }
                                className={styles.label}
                                color={Typography.COLOR.INHERIT}
                                content={
                                    <>
                                        {(position === 'left' || position === 'left-and-right') && (
                                            <span
                                                className={classnames(
                                                    styles['label__icon'],
                                                    styles['label__icon--left'],
                                                )}
                                            >
                                                {position === 'left-and-right'
                                                    ? iconElements[0]
                                                    : iconElements}
                                            </span>
                                        )}
                                        {label}
                                        {(position === 'right' ||
                                            position === 'left-and-right') && (
                                            <span
                                                className={classnames(
                                                    styles['label__icon'],
                                                    styles['label__icon--right'],
                                                )}
                                            >
                                                {position === 'left-and-right'
                                                    ? iconElements[1]
                                                    : iconElements}
                                            </span>
                                        )}
                                    </>
                                }
                            />
                        )}
                    </>
                ) : (
                    <>
                        {(position === 'left' || position === 'left-and-right') && (
                            <>{position === 'left-and-right' ? iconElements[0] : iconElements}</>
                        )}
                        {label && (
                            <Typography
                                variant={
                                    buttonStyleToTypographyVariant[buttonStyle] ??
                                    Typography.VARIANT.CTA1
                                }
                                className={styles.label}
                                color={Typography.COLOR.INHERIT}
                                content={label}
                            />
                        )}
                        {(position === 'right' || position === 'left-and-right') && (
                            <>{position === 'left-and-right' ? iconElements[1] : iconElements}</>
                        )}
                    </>
                )}
            </ButtonTag>
        );
    },
);

Button.displayName = 'Button';

Button.STYLE = {
    PRIMARY: 'primary',
    SECONDARY: 'secondary',
    TERTIARY: 'tertiary',
    TEXT: 'text',
    TEXT_SMALL: 'text-small',
    TEXT_EXTRA_SMALL: 'text-extra-small',
    TEXT_TITLE: 'text-title',
    BREADCRUMB: 'breadcrumb',
    UTILITY: 'utility',
    MAIN_NAV: 'main-nav',
    SECONDARY_SMALL: 'secondary-small',
    FILTER: 'filter',
};

Button.ICON_SIZE = {
    SMALL: 'small',
    MEDIUM: 'medium',
    LARGE: 'large',
};

Button.TARGET = {
    BLANK: '_blank',
    SELF: '_self',
    PARENT: '_parent',
    TOP: '_top',
};

const buttonStyleToTypographyVariant = {
    [Button.STYLE.TEXT_TITLE]: Typography.VARIANT.H6,
    [Button.STYLE.TEXT_SMALL]: Typography.VARIANT.CTA3,
    [Button.STYLE.TEXT_EXTRA_SMALL]: Typography.VARIANT.CTA4,
    [Button.STYLE.TEXT]: Typography.VARIANT.CTA2,
    [Button.STYLE.MAIN_NAV]: Typography.VARIANT.T1B,
    [Button.STYLE.BREADCRUMB]: Typography.VARIANT.BREADCRUMB,
    [Button.STYLE.UTILITY]: Typography.VARIANT.CTA1,
    [Button.STYLE.SECONDARY_SMALL]: Typography.VARIANT.LABEL1,
    [Button.STYLE.FILTER]: Typography.VARIANT.T4,
};

Button.propTypes = {
    /**
     * Unique ID for the button
     */
    id: PropTypes.string,
    /**
     * Style of the button element
     */
    buttonStyle: PropTypes.oneOf(Object.values(Button.STYLE)),
    /**
     * Icon Component
     */
    iconComponent: PropTypes.oneOfType([
        PropTypes.elementType,
        PropTypes.arrayOf(
            PropTypes.shape({
                iconName: Icon.propTypes.name,
                iconProps: PropTypes.object,
            }),
        ),
    ]),
    iconName: Icon.propTypes.name,
    iconSize: PropTypes.oneOf(Object.values(Button.ICON_SIZE)),
    iconRight: PropTypes.bool,
    alignment: PropTypes.oneOf(['left']),
    /**
     * Disabled attribute
     */
    disabled: PropTypes.bool,
    /**
     * Visible Label of the button
     */
    label: PropTypes.string,
    /**
     * Screen reader label for button
     */
    ariaLabel: PropTypes.string,
    /**
     * Optional Link Destination
     */
    href: PropTypes.string,
    /**
     * Target attribute for link
     */
    target: PropTypes.oneOf(Object.values(Button.TARGET)),
    /**
     * whether the link is internal
     */
    csr: PropTypes.bool,
    /**
     * Component to be used as a root element
     */
    component: PropTypes.elementType,
    /**
     * analytics object
     */
    analytics: customPropTypes.analyticsPropType,
    /**
     * On click callback
     */
    onClick: PropTypes.func,
    /**
     * Controls whether the button should take up the full width of its container
     */
    fullWidth: PropTypes.shape({
        sm: PropTypes.bool,
        md: PropTypes.bool,
        lg: PropTypes.bool,
    }),
};

Button.defaultProps = {
    buttonStyle: Button.STYLE.PRIMARY,
};

export default Button;
