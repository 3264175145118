import React from 'react';

import { Button } from '@/design-system/atoms/Button';
import { Image } from '@/design-system/atoms/Image';
import { Text } from '@/design-system/atoms/Text';
import { Theme } from '@/design-system/atoms/Theme';
import { Title } from '@/design-system/atoms/Title';
import { useBreakpoint } from '@/hooks/useBreakpoint';

import styles from './HeaderMenuCard.module.scss';

/*

 {
"id": "menu-card-f4d2b695a4",
"title": "Search by specialty",
"body": "Our dedicated insitutes and award-winning specialists offer patients access to the latest technology and techniques close to home.",
"cta": {
    "href": "/content/ahs/us/en/specialties.html",
    "ariaLabel": "Search by Specialty",
    "label": "Search by Specialty",
    "target": "_self",
    "buttonStyle": "primary",
    "csr": true
},
"image": {
    "id": "image-f4d2b695a4",
    "componentData": {
    "@type": "ahs/components/image",
    "repo:modifyDate": "2024-05-24T18:48:39Z",
    "image": {
        "repo:id": "d0497705-256f-4c7f-bfd5-67a2d2e9327d",
        "repo:modifyDate": "2024-04-24T22:18:08Z",
        "@type": "image/jpeg",
        "repo:path": "/content/dam/ahs/locations/MMC-Health-Pavilion-Rockaway-800x533.jpg"
    }
    },
    "width": "800",
    "alt": "Text",
    "src": "/content/experience-fragments/ahs/us/en/site/menu_cards/menu-card-1/master/_jcr_content/root/menu_card.coreimg.jpeg/1716576519838/mmc-health-pavilion-rockaway-800x533.jpeg",
    "height": "533",
    "uuid": "d0497705-256f-4c7f-bfd5-67a2d2e9327d",
    "areas": [],
    "widths": [],
    "sizes": "",
    "srcUriTemplate": "/content/experience-fragments/ahs/us/en/site/menu_cards/menu-card-1/master/_jcr_content/root/menu_card.coreimg{.width}.jpeg/1716576519838/mmc-health-pavilion-rockaway-800x533.jpeg",
    "lazyEnabled": true,
    "lazyThreshold": 0,
    "dmImage": false,
    "decorative": false,
    ":type": "ahs/components/image",
    "dataLayer": {
    "image-f4d2b695a4": {
        "@type": "ahs/components/image",
        "repo:modifyDate": "2024-05-24T18:48:39Z",
        "image": {
        "repo:id": "d0497705-256f-4c7f-bfd5-67a2d2e9327d",
        "repo:modifyDate": "2024-04-24T22:18:08Z",
        "@type": "image/jpeg",
        "repo:path": "/content/dam/ahs/locations/MMC-Health-Pavilion-Rockaway-800x533.jpg"
        }
    }
    }

*/

const HeaderMenuCard = ({ theme, id, title, body, cta, image, disableCSR, ...props }) => {
    const breakpoint = useBreakpoint();
    const isMobile = !breakpoint?.largeplus;

    return (
        <Theme name={theme} className={styles[`menu-card`]} {...props}>
            {image && !isMobile && (
                <Image {...image} decorative className={styles[`menu-card__image`]} />
            )}
            {(title || body) && (
                <div className={styles[`menu-card__content`]}>
                    {title && !isMobile && (
                        <Title
                            variant={Title.VARIANT.H5}
                            content={title}
                            className={styles[`menu-card__title`]}
                        />
                    )}
                    {body && (
                        <Text
                            variant={Text.VARIANT.T3}
                            content={body}
                            className={styles[`menu-card__description`]}
                        />
                    )}
                </div>
            )}
            {cta && (
                <Button
                    {...cta}
                    buttonStyle={Button.STYLE.PRIMARY}
                    className={styles[`menu-card__cta`]}
                    role="menuitem"
                    analytics={{
                        componentName: 'Navigation',
                        componentTitle: `${
                            isMobile ? 'mobile_header_card' : 'desktop_header_card'
                        }`,
                    }}
                    csr={disableCSR ? !disableCSR : cta?.csr}
                />
            )}
        </Theme>
    );
};

HeaderMenuCard.propTypes = {};

export default HeaderMenuCard;
