import classNames from 'classnames';
import { AnimatePresence, motion } from 'framer-motion';
import PropTypes from 'prop-types';
import React from 'react';
import { useTextField } from 'react-aria';

import { LazyIconAlertCircle } from '@/design-system/atoms/Icons/IconAlertCircle/Lazy';
import { Text } from '@/design-system/atoms/Text';
import SRText from '@/design-system/helpers/SRText';
import { composeRefs } from '@/utils/composeRefs';

import { Button } from '../../Button';
import styles from './TextField.module.scss';

export const TextFieldLayout = React.forwardRef((props, ref) => {
    const {
        className,
        iconComponent,
        buttonProps,
        variant = TextField.VARIANT.BORDER,
        label,
        isDisabled,
        isRequired,
        labelProps,
        inputProps,
        errorMessageProps,
        isInvalid,
        validationErrors,
        srError,
    } = props;

    const active = !!inputProps.value;

    const [type, adornment] = React.useMemo(() => {
        if (buttonProps) {
            return [
                'button',
                <Button
                    buttonStyle={Button.STYLE.TERTIARY}
                    iconSize="medium"
                    iconRight
                    disabled={isDisabled}
                    className={styles['textfield__adornment-button']}
                    {...buttonProps}
                />,
            ];
        }

        if (iconComponent) {
            const Icon = iconComponent;
            return ['icon', <Icon size="medium" />];
        }

        return [];
    }, [iconComponent, buttonProps, isDisabled]);

    const normalizedValidationErrors = [validationErrors].flatMap((v) => v).join(' ');

    return (
        <div
            className={classNames(styles['textfield'], className)}
            data-invalid={isInvalid ? true : undefined}
        >
            <div className={styles['textfield__wrapper']}>
                <label
                    className={classNames(
                        styles['textfield__label'],
                        active && styles['textfield__label--active'],
                        isDisabled && styles['textfield__label--disabled'],
                    )}
                    {...labelProps}
                >
                    {label}
                    {isRequired ? ' *' : ''}
                </label>
                <input
                    ref={ref}
                    className={classNames(
                        styles['textfield__input'],
                        variant === TextField.VARIANT.BOTTOM_BORDER &&
                            styles['textfield__input--bottom-border'],
                        variant === TextField.VARIANT.NO_BORDER &&
                            styles['textfield__input--no-border'],
                        type === 'icon' && styles['textfield__input--icon'],
                        type === 'button' && styles['textfield__input--button'],
                    )}
                    {...inputProps}
                />
                {adornment && (
                    <div
                        className={classNames(
                            styles['textfield__adornment'],
                            isDisabled && styles['textfield__adornment--disabled'],
                            isInvalid && styles['textfield__adornment--error'],
                            type === 'icon' && styles['textfield__adornment--icon'],
                            type === 'button' && styles['textfield__adornment--button'],
                        )}
                    >
                        {adornment}
                    </div>
                )}
            </div>

            {srError && isInvalid && normalizedValidationErrors && (
                <SRText {...errorMessageProps} content={normalizedValidationErrors} />
            )}
            {!srError && (
                <AnimatePresence>
                    {isInvalid && normalizedValidationErrors && (
                        <motion.div
                            initial={{ height: 0, opacity: 0 }}
                            animate={{ height: 'auto', opacity: 1 }}
                            exit={{ height: 0, opacity: 0 }}
                            {...errorMessageProps}
                            className={styles['textfield__field-error']}
                        >
                            <LazyIconAlertCircle size="small" />
                            <Text
                                tag={Text.TAG.SPAN}
                                variant="t3"
                                content={normalizedValidationErrors}
                            />
                        </motion.div>
                    )}
                </AnimatePresence>
            )}
        </div>
    );
});

/**
 * The TextField component is a customized input field, that uses `useTextField` from `react-aria`.
 * This component pass down all props to `useTextField` and add 3 custom props for better dev experience.
 *
 * PROPS:
 * - All `useTextField` props
 * - `iconComponent`: icon to be rendered inside the input field
 * - `variant`: input variant style
 * - `label`: input label
 *
 * `useTextField` docs: https://react-spectrum.adobe.com/react-aria/useTextField.html
 *
 * For validation take a look at:
 * - https://react-spectrum.adobe.com/react-aria/useTextField.html#validation
 * - https://react-spectrum.adobe.com/react-aria/forms.html#validation
 */
export const TextField = React.forwardRef((props, ref) => {
    const { validationErrors } = props;

    const internalRef = React.useRef();
    const textFieldProps = useTextField(props, internalRef);

    return (
        <TextFieldLayout
            ref={composeRefs(ref, internalRef)}
            {...props}
            {...textFieldProps}
            validationErrors={validationErrors}
        />
    );
});

TextField.VARIANT = {
    BORDER: 'border',
    BOTTOM_BORDER: 'bottom-border',
    NO_BORDER: 'no-border',
};

TextField.displayName = 'TextField';

TextField.propTypes = {
    /**
     * Input field style variant
     */
    variant: PropTypes.oneOf(Object.values(TextField.VARIANT)),
    /**
     * Indicates the availability and type of interactive popup element, such as menu or dialog, that can be triggered by an element.
     */
    isDisabled: PropTypes.bool,
    /**
     * Whether the input can be selected but not changed by the user.
     */
    isRequired: PropTypes.bool,
    /**
     * Whether to use native HTML form validation to prevent form submission when the value is missing or invalid, or mark the field as required or invalid via ARIA.
     */
    validate: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
    /**
     * Button Props
     */
    buttonProps: PropTypes.shape(Button.propTypes),
    /**
     * Icon Component
     */
    iconComponent: PropTypes.elementType,
    /**
     * Whether the input value is invalid.
     */
    isInvalid: PropTypes.bool,
    /**
     * Error message
     */
    validationErrors: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
    /**
     * Show error message as SR Text
     */
    srError: PropTypes.bool,
};
