import PropTypes from 'prop-types';
import React from 'react';

import styles from './SRText.module.scss';

const SRText = ({ content, ...props }) => {
    return (
        <span {...props} className={styles.srText}>
            {content}
        </span>
    );
};

SRText.propTypes = {
    /**
     * Content of the Screen Reader Text
     */
    content: PropTypes.string,
};

export default SRText;
