/**
 * Link prop type. Used to validate a link object.
 */

import PropTypes from 'prop-types';

const linkPropType = PropTypes.shape({
    href: PropTypes.string,
    label: PropTypes.string,
    ariaLabel: PropTypes.string,
    target: PropTypes.oneOf(['_blank', '_self', '_parent', '_top']),
    csr: PropTypes.bool,
});

export default linkPropType;
