import { useEffect, useRef } from 'react';

import window from '@/utils/window';

/**
 *
 * @param {*} eventName
 * @param {*} handler
 * @param {*} element
 */
const useEventListener = (eventName, handler, element = window) => {
    const savedHandler = useRef(null);

    useEffect(() => {
        savedHandler.current = handler;
    }, [handler]);

    useEffect(() => {
        const isSupported = element && element.addEventListener;
        if (!isSupported) return;

        const eventListener = (event) => savedHandler.current(event);
        element.addEventListener(eventName, eventListener);

        return () => {
            element.removeEventListener(eventName, eventListener);
        };
    }, [eventName, element]);
};

export default useEventListener;
