import classnames from 'classnames';
import { AnimatePresence } from 'framer-motion';
import PropTypes from 'prop-types';
import React from 'react';
import { useComboBox } from 'react-aria';
import ReactDOM from 'react-dom';
import { useComboBoxState } from 'react-stately';

import { TextField, TextFieldLayout } from '@/design-system/atoms/Forms/TextField';
import { composeRefs } from '@/utils/composeRefs';
import window from '@/utils/window';

import { List } from '../List';
import { Popover } from '../Popover';
import styles from './ComboBox.module.scss';

/**
 * This component is responsible to control the typeahead state and behaviors.
 * To use this component you need to pass a list of Item from 'react-stately',
 * check the Typeahead component for more details.
 *
 * This component uses [`useComboBoxState`](https://react-spectrum.adobe.com/react-stately/useComboBoxState.html)
 * from 'react-stately' and [`useComboBox` ](https://react-spectrum.adobe.com/react-aria/useComboBox.html)
 * from 'react-aria' all props passed to this component are passed down to both
 * hooks.
 */
export const ComboBox = React.forwardRef((props, ref) => {
    const {
        label,
        iconComponent,
        buttonProps,
        hasFixedItem,
        debug,
        items,
        variant,
        validationErrors,
        srError,
        wrapperClassName,
        popoverClassName,
    } = props;

    const internalRef = React.useRef();
    const [inputWidth, setInputWidth] = React.useState(0);
    const [isFocused, setIsFocused] = React.useState(false);

    const state = useComboBoxState({ ...props });

    const inputRef = React.useRef();
    const listBoxRef = React.useRef();
    const popoverRef = React.useRef();

    const comboBoxOpen = React.useCallback(() => {
        state.open();
    }, [state]);

    const comboBoxClose = React.useCallback(() => {
        state.close();
    }, [state]);

    const comboBoxProps = useComboBox(
        {
            ...props,
            inputRef,
            listBoxRef,
            popoverRef,
        },
        state,
    );
    const inputProps = React.useMemo(
        () => ({
            ...comboBoxProps.inputProps,
            'aria-owns': comboBoxProps.listBoxProps.id,
            onClick(...args) {
                comboBoxProps.inputProps.onClick?.(...args);
                comboBoxOpen();
            },
            onFocus(...args) {
                comboBoxProps.inputProps.onFocus?.(...args);
                comboBoxOpen();
            },
            onBlur(...args) {
                comboBoxProps.inputProps.onBlur?.(...args);
                comboBoxClose();
            },
            onKeyDown(...args) {
                comboBoxProps.inputProps.onKeyDown?.(...args);
                if (args[0].key === 'Enter') {
                    // if the list is open and the user presses enter without
                    // selecting an item, close the list and submit the form
                    if (state.isOpen && !isFocused) {
                        const submitEvent = new Event('submit', {
                            bubbles: true,
                            cancelable: true,
                        });
                        inputRef.current.form?.dispatchEvent(submitEvent);
                        comboBoxClose();
                    } else if (state.isOpen && isFocused) {
                        // if the list is open and an item is focused, select it
                        state.selectionManager.select(state.focusedKey);
                    }
                }
            },
        }),
        [
            comboBoxProps.inputProps,
            comboBoxProps.listBoxProps.id,
            comboBoxOpen,
            comboBoxClose,
            state,
            isFocused,
        ],
    );

    React.useEffect(() => {
        let requestAnimationFrameId = -1;

        const handleInputResize = () => {
            if (!internalRef.current || !inputRef.current) {
                requestAnimationFrameId = window.requestAnimationFrame(handleInputResize);
                return;
            }

            const { width } = inputRef.current.getBoundingClientRect();
            setInputWidth(Math.ceil(width));

            requestAnimationFrameId = window.requestAnimationFrame(handleInputResize);
        };

        handleInputResize();

        return () => {
            window.cancelAnimationFrame(requestAnimationFrameId);
        };
    }, []);

    React.useEffect(() => {
        if (typeof window === 'undefined') {
            return;
        }

        // need to add the css var at root, because popover is rendered using portal
        document.documentElement.style.setProperty(
            '--combo-box-list-item-length',
            `${Math.min(state.collection.size, 6)}`,
        );
    }, [state.collection.size]);

    React.useEffect(() => {
        if (items == null || document.activeElement !== inputRef.current) {
            return;
        }

        if (items.length === 0) {
            state.close();
            return;
        }

        const itemsLength = hasFixedItem ? items.length + 1 : items.length;

        let isSizeDifferent = itemsLength !== state.collection.size;
        let hasDifferentKeys = false;
        if (isSizeDifferent === false) {
            for (let i of items) {
                if (state.collection.getItem(`${i.id}`) == null) {
                    hasDifferentKeys = true;
                    break;
                }
            }
        }

        // force rerender the combo box list
        if ((isSizeDifferent || hasDifferentKeys) && !state.selectedItem) {
            state.open();
        }
    }, [hasFixedItem, items, state]);

    return (
        <div
            ref={internalRef}
            className={classnames(styles['combo-box'], wrapperClassName)}
            style={{ '--combo-box-input-width': `${inputWidth}px` }}
            data-popover
        >
            <TextFieldLayout
                ref={composeRefs(ref, inputRef)}
                variant={variant ? variant : TextField.VARIANT.BORDER}
                label={label}
                {...comboBoxProps}
                inputProps={inputProps}
                iconComponent={iconComponent}
                buttonProps={buttonProps}
                className={props.className}
                validationErrors={validationErrors}
                srError={srError}
            />
            <AnimatePresence>
                {state.isOpen && (
                    <Popover
                        popoverRef={popoverRef}
                        triggerRef={inputRef}
                        state={state}
                        placement="bottom start"
                        width={inputWidth}
                        className={popoverClassName}
                    >
                        <List
                            {...comboBoxProps.listBoxProps}
                            listBoxRef={listBoxRef}
                            state={state}
                            hasFixedItem={hasFixedItem}
                            setIsFocused={setIsFocused}
                        />
                    </Popover>
                )}
            </AnimatePresence>

            {debug &&
                ReactDOM.createPortal(
                    <div style={{ position: 'absolute', bottom: 0, right: 0, zIndex: 999999 }}>
                        <button
                            onClick={(e) => {
                                e.preventDefault();
                                state.open();
                            }}
                        >
                            Open/Close DropDown
                        </button>
                    </div>,
                    document.body,
                )}
        </div>
    );
});

ComboBox.propTypes = {
    /**
     * Input Label
     */
    label: PropTypes.string,
    /**
     * Input Icon
     */
    iconComponent: PropTypes.elementType,
    /**
     * If the list has a fixed item at the bottom
     */
    hasFixedItem: PropTypes.bool,
    /**
     * Variant of the TextField
     */
    variant: PropTypes.oneOf(Object.values(TextField.VARIANT)),
};
